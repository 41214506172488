<script setup>
import { transl } from "@/composables/useTranslation";
const props = defineProps(["dialog"]);
const emit = defineEmits(["update:dialog"]);

const dialog = computed({
  get: () => props.dialog,
  set: (val) => emit("update:dialog", val),
});

function close() {
  dialog.value = false;
}
</script>
<template>
  <v-dialog
    v-model="dialog"
    class="center"
    :options="{
      maxHeight: '90vh',
    }"
  >
    <!-- <div class="header"><div class="top">Hello titl</div></div> -->
    <div class="content">
      <div class="close-dialog" @click="close()">
        <img src="/icons/close.svg" alt="" />
      </div>
      <V-PolicyEn />
      <V-PolicyRU />
      <V-PolicyUA />
    </div>
    <div class="footer">
      <div class="close-button" @click="close()">
        {{ transl("Texts:Close") }}
      </div>
    </div>
  </v-dialog>
</template>
<style scoped>
.content {
  overflow-y: auto;
  padding: 20px;
  position: relative;
}
@media (max-width: 720px) {
  .content {
    padding: 0;
  }
}
.top {
  padding: 20px;
  font-size: 40px;
}
.footer {
  display: flex;
  justify-content: center;
}
.close-dialog {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  box-sizing: border-box;
}
.close-dialog:hover {
  opacity: 0.8;
}

.close-button {
  align-items: center;
  background: #b1b1b1;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 13px;
  font-weight: 500;
  max-width: fit-content;
  padding: 19px 37px;
  text-transform: uppercase;
  margin: 20px;
  font-weight: 600;
}

@media (hover: hover) {
  .close-button:hover {
    background: linear-gradient(180deg, #ffcb7e, #dd9223);
  }
}
@media (hover: none) {
  .close-button:active {
    background: linear-gradient(180deg, #ffcb7e, #dd9223);
  }
}
</style>
~/composables/useTranslation